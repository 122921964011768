<template>
  <a-menu
    v-model:openKeys="state.openKeys"
    v-model:selectedKeys="state.selectedKeys"
    theme="dark"
    :mode="props.mode"
    :style="{ width: props.mode === 'horizontal' ? '' : `${menuCollapsed ? sideBarCollapsedWidth : sideBarWidth}px`, height: props.mode === 'horizontal' ? `${globalHeaderHeight}px` : '100vh' }"
  >
    <template v-for="item in props.menu" :key="item.meta.fullPath">
      <!-- children hideChildrenInMenu  判断 menu 是否包含 children 或是否 需要隐藏 children  ｜  不包含children 或 需要隐藏 children 直接渲染-->
      <template v-if="!item.children || item.meta?.hideChildrenInMenu">
        <!-- hideInMenu 判断 是否需要隐藏该项-->
        <a-menu-item v-if="!item.meta?.hideInMenu" :key="item.meta?.fullPath">
          <template #icon>
            <svg-icon :name="item.meta?.icon || 'home'" color="rgba(255,255,255,0.85)" />
          </template>
          <a :target="item.meta?.target" :href="item.meta?.fullPath">
            <span>{{ item.meta?.title }}</span>
          </a>
        </a-menu-item>
      </template>
      <template v-else>
        <sub-menu :menu="item" />
      </template>
    </template>
  </a-menu>
</template>

<script setup lang="ts">
  import { PropType, reactive, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { MenuMode } from 'ant-design-vue/lib/menu/src/interface'
  import { RouteRecordRaw, useRoute } from 'vue-router'
  import { AppStores } from '@/stores/modules/app'

  import SubMenu from './subMenu.vue'

  defineOptions({ name: 'MenuIndex' })
  const stores = AppStores()
  const { menuCollapsed, sideBarWidth, sideBarCollapsedWidth, globalHeaderHeight } = storeToRefs(stores)

  const route = useRoute()
  const props = defineProps({
    mode: {
      type: String as PropType<MenuMode>,
      require: true
    },
    menu: {
      type: Array as PropType<Array<RouteRecordRaw>>,
      require: true
    }
  })

  const state = reactive({
    openKeys: [],
    selectedKeys: []
  }) as {
    openKeys: string[]
    selectedKeys: string[]
  }
  watch(
    () => route.path,
    () => {
      props.mode !== 'horizontal'
        ? route.matched.forEach(item => {
            state.openKeys.push(item.path)
          })
        : []
      state.selectedKeys = [route.meta.fullPath as string]
    },
    {
      immediate: true
    }
  )
</script>

<style lang="less" scoped>
  .ant-menu {
    overflow: auto;

    .ant-menu-item {
      color: rgb(255 255 255 / 85%);

      a::before {
        content: none;
      }
    }
  }
</style>
