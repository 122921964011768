<template>
  <template v-if="props.data.length > 0">
    <a-list item-layout="horizontal" :data-source="props.data" :loading="props.loading">
      <template #renderItem="{ item }: { item: NoticeItem }">
        <a-list-item>
          <template #actions>
            <template v-if="props.showActions">
              <span> {{ dayjs(item.lastModificationTime || item.creationTime).format('YYYY-MM-DD') }} </span>
              <template v-if="props.showControl">
                <a-popconfirm title="确定删除此公告" ok-text="确定" cancel-text="取消" @confirm="emit('handle', { type: 'delete', id: item.id })">
                  <a key="list-loadmore-more" v-auth="'Admin.Admin.Notice'">删除</a>
                </a-popconfirm>
                <a key="list-loadmore-edit" v-auth="'Admin.Admin.Notice'" @click="emit('handle', { type: 'update', id: item.id })">编辑</a>
              </template>
              <!-- <a key="list-loadmore-more" @click="emit('handle', { type: 'show', id: item.id })">查看</a> -->
            </template>
          </template>
          <a-list-item-meta>
            <template #title>
              <a-button type="link" @click="emit('handle', { type: 'show', id: item.id })"> {{ item.title }}</a-button>
            </template>
            <template #description>
              <template v-if="props.showLawfirm">
                接收分所:
                <a-space :size="24" wrap>
                  <template v-if="item.isAll"> 全部分所 </template>
                  <template v-for="items in item.lawfirms" :key="items.lawfirmId">
                    {{ items.lawfirmName }}
                  </template>
                </a-space>
              </template>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </template>
  <template v-else>
    <a-empty :description="props.emptyText" />
  </template>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'
  import type { NoticeItem } from '@/types/notice'

  defineOptions({ name: 'NoticeList' })

  const props = defineProps({
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array<NoticeItem>,
      required: true,
      default: []
    },
    emptyText: {
      type: String,
      default: '暂未发布相关公告'
    },
    showControl: {
      type: Boolean,
      default: true
    },
    showLawfirm: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    }
  })

  const emit = defineEmits(['handle'])
</script>
<style lang="less" scoped>
  .ant-list {
    .ant-list-item {
      padding: 12px 0;
    }
  }

  :deep(.ant-space) {
    margin-bottom: 0 !important;
  }
</style>
