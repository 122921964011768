import request from '@/utils/request'
import type { CheckExist } from '@/types/typing'
import type { PageResultData, RequestResult } from '@/types/request'
import type { ClientSourceItem, ClientSourceParams } from '@/types/system/clientSource'

/**
 * 客户来源
 * 获取客户来源列表
 * @param {ClientSourceParams} ClientSourceParams
 * @returns {PageResultData<ClientSourceItem>}
 */
export async function getClientSourceList(ClientSourceParams: ClientSourceParams) {
  return request.get<ClientSourceParams, RequestResult<PageResultData<ClientSourceItem>>>('/api/ClientSource/GetPagedList', { params: ClientSourceParams })
}

/**
 * 客户来源-没有分页
 * @returns {PageResultData<ClientSourceItem>}
 */
export async function getClientSourceListAll() {
  return request.get<null, RequestResult<Array<ClientSourceItem>>>('/api/ClientSource/GetList')
}
/**
 * 根据ID获取客户来源详情
 * @param {number} id
 * @returns {RequestResult<ClientSourceItem>}
 */
export async function getClientSourceInfo(id: number) {
  return request.get<number, RequestResult<ClientSourceItem>>(`/api/ClientSource/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<ClientSourceItem>}
 */
export async function checkClientSourceName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/ClientSource/CheckExist`, CheckExist)
}

/**
 * 创建客户来源
 * @param {ClientSourceItem} data
 * @returns {RequestResult}
 */
export async function createClientSource(data: ClientSourceItem) {
  return request.post<ClientSourceItem, RequestResult<null>>(`/api/ClientSource/create`, data)
}

/**
 * 编辑客户来源
 * @param {ClientSourceItem} data
 * @returns {RequestResult}
 */
export async function updateClientSource(data: ClientSourceItem) {
  return request.put<ClientSourceItem, RequestResult<null>>(`/api/ClientSource/update`, data)
}

/**
 * 删除客户来源
 * @param {ClientSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteClientSource(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/ClientSource/delete/${id}`)
}

/**
 * 软删除客户来源
 * @param {ClientSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteClientSourceSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/ClientSource/SoftDelete/${id}`)
}
