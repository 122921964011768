import request from '@/utils/request'
import type { PageResultData, RequestResult } from '@/types/request'
import type { NotificationItem, NotificationParams } from '@/types/notification/manage'

/**
 * 获取消息通知
 * @param { NotificationParams } params
 * @returns { RequestResult<PageResultData<NotificationItem>> }
 */
export async function getNotificationPageList(params: NotificationParams) {
  return request.get<NotificationParams, RequestResult<PageResultData<NotificationItem>>>('/api/Notification/getPagedList', { params: params })
}

/**
 * 获取公告详情
 * @param { number } id
 * @returns { RequestResult<NotificationItem> }
 */
export async function getNotificationInfo(id: number) {
  return request.get<number, RequestResult<NotificationItem>>(`/api/Notification/getById/${id}`)
}
