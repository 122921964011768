import request from '@/utils/request'

import type { PageResultData, RequestResult } from '@/types/request'
import type { PaymentChannelCreateOrUpdate, PaymentChannelItem, PaymentChannelParams } from '@/types/system/paymentChannel'

/**
 * 获取支付渠道列表
 */
export async function getPaymentChannelList() {
  return request.get<null, RequestResult<Array<PaymentChannelItem>>>('/api/PaymentChannel/GetList')
}
/**
 * 分页获取支付渠道列表
 */
export async function getPaymentChannelPagedList(PaymentChannelParams: PaymentChannelParams) {
  return request.get<null, RequestResult<PageResultData<PaymentChannelItem>>>('/api/PaymentChannel/GetPagedList', { params: PaymentChannelParams })
}
/**
 * 根据ID获取支付渠道详情
 * @param {number} id
 * @returns {RequestResult<PaymentChannelItem>}
 */
export async function getPaymentChannelInfo(id: number) {
  return request.get<number, RequestResult<PaymentChannelItem>>(`/api/PaymentChannel/GetById/${id}`)
}
/**
 * 创建支付渠道
 * @param {PaymentChannelCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createPaymentChannel(data: PaymentChannelCreateOrUpdate) {
  return request.post<PaymentChannelCreateOrUpdate, RequestResult<null>>(`/api/PaymentChannel/Create`, data)
}

/**
 * 编辑支付渠道
 * @param {PaymentChannelCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updatePaymentChannel(data: PaymentChannelCreateOrUpdate) {
  return request.put<PaymentChannelCreateOrUpdate, RequestResult<null>>(`/api/PaymentChannel/Update`, data)
}

/**
 * 删除支付渠道
 * @param {number} data
 * @returns {RequestResult}
 */
export async function deletePaymentChannel(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/PaymentChannel/Delete/${id}`)
}
/**
 * 软删除支付渠道
 * @param {number} data
 * @returns {RequestResult}
 */
export async function deletePaymentChannelSoft(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/PaymentChannel/softdelete/${id}`)
}
