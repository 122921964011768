import request from '@/utils/request'
import type { RequestResult, PageResultData } from '@/types/request'
import type { NotificationParams, NotificationItem } from '@/types/notification/manage'

/**
 * 获取消息通知
 * @param { NotificationParams } params
 * @returns { RequestResult<PageResultData<NotificationItem>> }
 */
export async function getNotificationPageList(params: NotificationParams) {
  return request.get<NotificationParams, RequestResult<PageResultData<NotificationItem>>>('/api/Notification/getPagedList', { params: params })
}

/**
 * 获取消息通知
 * @param { NotificationParams } params
 * @returns { RequestResult<PageResultData<NotificationItem>> }
 */
export async function getNotificationPageListForManage(params: NotificationParams) {
  return request.get<NotificationParams, RequestResult<PageResultData<NotificationItem>>>('/api/Notification/getPagedListForAdmin', { params: params })
}
