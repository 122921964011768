import request from '@/utils/request'

import type { RequestResult } from '@/types/request'
import type { InvoiceTypeItem, InvoiceTypeParam } from '@/types/system/invoiceType'

export async function getInvoiceTypeList() {
  return request.get<null, RequestResult<Array<InvoiceTypeItem>>>('/api/InvoiceType/GetList')
}
/**
 * 用在开票页面
 * @param {InvoiceTypeParam} params
 */

export async function getInvoiceTypeListfilter(params: InvoiceTypeParam) {
  return request.get<null, RequestResult<Array<InvoiceTypeItem>>>('/api/InvoiceType/GetList', { params: params })
}
