<template>
  <a-style-provider :hash-priority="'high'" :transformers="[legacyLogicalPropertiesTransformer]">
    <a-config-provider :locale="locale" :component-size="size" :theme="configThem">
      <router-view :key="$route.fullPath" />
      <template v-if="!$route.path.includes('/outside') && !$route.path.includes('/install')">
        <a-float-button
          :style="{
            right: '24px'
          }"
          @click="openAliGPT"
        >
          <template #icon>
            <svg-icon name="oa_robot" />
          </template>
        </a-float-button>
      </template>
    </a-config-provider>
  </a-style-provider>
</template>

<script setup lang="ts">
  import { reactive, ref } from 'vue'
  import { notification, legacyLogicalPropertiesTransformer, theme } from 'ant-design-vue'
  import convertLegacyToken from 'ant-design-vue/lib/theme/convertLegacyToken'
  import zhCN from 'ant-design-vue/es/locale/zh_CN'
  import { isMobile } from '@/utils/broswer'
  import { AppStores } from '@/stores/modules/app'
  import { ThemeStores } from '@/stores/modules/theme'
  import { componentSize } from '@/config'
  import variable from '@/themVariable'
  defineOptions({ name: 'OAApp' })

  const stores = AppStores()
  const locale = zhCN
  isMobile() === 'mobile' ? (stores.isMobile = true) : (stores.isMobile = false)

  const size = ref(componentSize)

  // less 自定义变量 参考值
  const themeStores = ThemeStores()
  const { defaultAlgorithm, defaultSeed } = theme
  const token = theme.defaultAlgorithm({ ...defaultSeed, ...variable })
  const mapToken = defaultAlgorithm({ ...defaultSeed, ...variable })
  const v3Token = convertLegacyToken(mapToken)
  themeStores.token = { V4: { ...token }, V3: { ...v3Token } }

  const configThem = reactive({
    token: variable,
    algorithm: defaultAlgorithm,
    components: {
      Statistic: {
        fontSize: 14,
        fontSizeHeading3: 14
      },
      Button: {
        size: 'small'
      },
      Pagination: {
        size: 'small'
      },
      Table: {
        size: 'small',
        paddingXS: 4
      },
      Descriptions: {
        paddingXS: 4,
        padding: 8
      }
    }
  })

  notification.config({
    top: '60px',
    duration: 3
  })

  const openAliGPT = () => {
    window.open('/outside/aligpt', '_blank')
  }
</script>
<style lang="less" scoped>
  .ant-float-btn {
    width: 60px;
    height: 60px;
    background-color: #1677ff;

    :deep(.ant-float-btn-body) {
      background-color: #1677ff;
    }

    :deep(.ant-float-btn-content) {
      width: 60px;
      height: 60px;
      padding: 2px 0 !important;

      .ant-float-btn-icon {
        width: 60px !important;
      }
    }

    .svg-icon {
      font-size: 28px;
      color: #fff;
    }
  }
</style>
