import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'

import { Account } from './account'
import { Permission } from './permission'
import { Base } from './base'
import { SystemCaseReason } from './system/caseReason'
import { SystemClientSource } from './system/clientSource'
import { SystemProjectNormalType } from './system/projectNormalType'
import { SystemIndustry } from './system/industry'
import { SystemPaymentMethod } from './system/paymentMethod'
import { SystemRegion } from './system/region'
import { SystemComplaintSource } from './system/complaintSource'
import { SystemOrgainzation } from './system/organization'
import { SystemProjectSource } from './system/projectSource'
import { SystemInvoiceType } from './system/invoiceType'
import { SystemPaymentchannel } from './system/paymentChannel'

export const AppStores = defineStore('AppStores', {
  state: () => {
    return {
      loading: false,
      defaultData: [] as Array<string>, //  已初始化的默认数据
      menuCollapsed: false, // 导航菜单展开\收缩状态
      sideBarWidth: 220, // 左侧导航展开宽度
      sideBarCollapsedWidth: 64, // 左侧导航收缩宽度
      // globalHeaderWidth: '', // 顶部 header 宽度
      globalHeaderHeight: 52, // 顶部 header 高度
      isMobile: false // 是否是手机打开
    }
  },
  getters: {
    globalHeaderWidth(state) {
      return (layout: string) => {
        // return state.menuCollapsed ? `calc(100% - ${state.sideBarCollapsedWidth}px)` : `calc(100% - ${state.sideBarWidth}px)`
        return state.isMobile || layout === 'user' ? '100%' : state.menuCollapsed ? `calc(100% - ${state.sideBarCollapsedWidth}px)` : `calc(100% - ${state.sideBarWidth}px)`
      }
    }
  },
  actions: {
    async getAppDfaultMustData() {
      try {
        await Account().getAccoutInfo() //获取当前登录用户信息
        await Permission().getUserPermission() // 获取当前登录用户所有权限(功能权限) 生成路由时必须使用此数据
        await Permission().getUserRangeList() //  // 获取当前登录用户所有权限(数据权限)
      } catch (error) {
        console.log(error)
      }
    },

    async getAppDefaultBaseData() {
      try {
        const route = useRoute()

        await Base().appLawfirmParams(Account().userLawfirmId) // 获取当前登录用户功能菜单列表
        await Base().appUserParams() //获取当前用户配置

        // 获取投诉来源
        if (route.fullPath.includes('/complaint')) {
          !this.defaultData.includes('complaintSource') ? (await SystemComplaintSource().getList(), this.defaultData.push('complaintSource')) : ''
        }

        if (route.fullPath.includes('/project')) {
          !this.defaultData.includes('clientSource') ? (await SystemClientSource().getList(), this.defaultData.push('clientSource')) : ''
          !this.defaultData.includes('projectNormalType') ? (await SystemProjectNormalType().getList(), this.defaultData.push('projectNormalType')) : ''
          !this.defaultData.includes('projectSource') ? (await SystemProjectSource().getList(), this.defaultData.push('projectSource')) : ''
          !this.defaultData.includes('caseReason') ? (await SystemCaseReason().getCaseReasoTree(), this.defaultData.push('caseReason')) : ''
          !this.defaultData.includes('industry') ? (await SystemIndustry().getIndustryTree(), this.defaultData.push('industry')) : ''
          !this.defaultData.includes('organization') ? (await SystemOrgainzation().getOrganizationData(), this.defaultData.push('organization')) : ''
          !this.defaultData.includes('paymentMethod') ? (await SystemPaymentMethod().getPaymentMethodTree([]), this.defaultData.push('paymentMethod')) : ''
          !this.defaultData.includes('region') ? (await SystemRegion().getRegionTree(), this.defaultData.push('region')) : ''
        }
        if (route.fullPath.includes('/letter')) {
          !this.defaultData.includes('projectNormalType') ? (await SystemProjectNormalType().getList(), this.defaultData.push('projectNormalType')) : ''
        }
        if (route.fullPath.includes('/invoice')) {
          !this.defaultData.includes('invoiceType') ? (await SystemInvoiceType().getList(), this.defaultData.push('invoiceType')) : ''
        }
        if (route.fullPath.includes('/payment')) {
          !this.defaultData.includes('paymentChannel') ? (await SystemPaymentchannel().getList(), this.defaultData.push('paymentChannel')) : ''
        }
      } catch (error) {}
    }
  }
})
