<template>
  <template v-if="isMobile">
    <a-drawer :placement="'left'" :closable="false" :open="state.visible" :width="sideBarWidth" root-class-name="sidebardrawer" @close="close">
      <side-bar :menu="menu" />
    </a-drawer>
  </template>
  <a-layout>
    <global-header :layout="'user'" @change-drawer="changeDrawer">
      <template v-if="!isMobile" #menu>
        <menu-index :mode="'horizontal'" :menu="menu" />
      </template>
      <template #rightContent>
        <div class="layoutuser">
          <a-space :size="14">
            <notice-icon :layout="'user'" />
            <avatar-dropdown />
          </a-space>
        </div>
      </template>
    </global-header>
    <a-layout-content class="use_layout">
      <a-spin :spinning="loading" :tip="loadingTitle">
        <router-view :key="$route.fullPath" />
      </a-spin>
    </a-layout-content>
    <global-footer />
  </a-layout>
</template>

<script setup lang="ts">
  import { reactive } from 'vue'
  import { storeToRefs } from 'pinia'
  import { loadingTitle } from '@/config'

  import { AppStores } from '@/stores/modules/app'
  import { Permission } from '@/stores/modules/permission'
  import { Base, LayoutTypeCode } from '@/stores/modules/base'

  import SideBar from './components/sideBar/index.vue'
  import GlobalHeader from './components/globalHeader/index.vue'
  import GlobalFooter from './components/globalFooter/index.vue'
  import MenuIndex from './components/menu/index.vue'
  import NoticeIcon from './components/noticeIcon/index.vue'
  import AvatarDropdown from './components/avatarDropdown.vue'

  defineOptions({ name: 'UserLayout' })
  const stores = AppStores()

  const permissionStores = Permission()
  const { isMobile, sideBarWidth, loading } = storeToRefs(stores)

  const baseStore = Base()
  baseStore.viewLayout = LayoutTypeCode.User

  const state = reactive({
    visible: false
  })
  const menu = permissionStores.allowUserRouters
  const changeDrawer = (value: boolean) => {
    state.visible = value
  }
  const close = () => {
    state.visible = false
    stores.menuCollapsed = true
  }
</script>
<style lang="less" scoped>
  .ant-layout {
    overflow: auto;

    .ant-layout-content {
      min-height: 92vh;
      margin: 0 16px;
    }
  }

  :deep(.layoutuser) {
    .name {
      color: #fff;
    }
  }
</style>
<style lang="less">
  .sidebardrawer {
    .ant-drawer-body {
      padding: 0 !important;
    }
  }
</style>
