import { defineStore } from 'pinia'
import { notification } from 'ant-design-vue'
import router from '@/router'

import { tokenTableName, refreshTokenTableName } from '@/config'
import { get, remove, removeAll, set } from '@/utils/cookies'

import { Permission } from '../permission'

import { getAccountCurrentUser, postAccountByCode, postAccountLogin } from '@/api/account/login'

import type { AccountInfo, LoginParams, LoginParamsForCode, RoleDataRangeItem, Token } from '@/types/account'

export const Account = defineStore('Account', {
  state: () => {
    return {
      token: {
        accessToken: '',
        refreshToken: ''
      },
      userInfo: {
        id: undefined,
        userName: '', // 用户名
        realName: '', //真实姓名
        avatar: '', //头像
        sex: false, //性别
        email: '', //邮箱
        phoneNumber: '', //电话号码
        licenseNumber: '', //执业证号

        isLawyer: false, //人员类别
        userTypeId: 0,
        userTypeName: '',

        isEnabled: true, //用户状态
        lawfirmId: 0, // 律所
        lawfirmName: '',
        lawfirmFullName: '',
        organizationUnitId: 0, //组织机构
        organizationUnitName: '',
        organizationUnitFullName: ''
      },
      extraUserInfo: undefined,
      roleRange: []
    } as {
      token: Token
      userInfo: AccountInfo
      extraUserInfo?: AccountInfo
      roleRange: Array<RoleDataRangeItem>
    }
  },
  getters: {
    userId: state => state.userInfo.id,
    userRealName: state => state.userInfo.realName,
    userAvatar: state => state.userInfo.avatar,
    userIsEnabled: state => state.userInfo.isEnabled,
    userTypeIsLawyer: state => state.userInfo.isLawyer,
    userLawfirmId: state => state.userInfo.lawfirmId,
    userLawfirmName: state => state.userInfo.lawfirmName,
    userOrganizationUnitId: state => state.userInfo.organizationUnitId,
    userOrganizationUnitFullName: state => state.userInfo.organizationUnitFullName,
    userInfoExtra: state => state.extraUserInfo,
    userRoleRange: state => state.roleRange
  },
  actions: {
    // 账号密码&验证码登录通用
    async login(info: LoginParams) {
      const res = await postAccountLogin(info)
      const { data, succeeded } = res
      if (succeeded) {
        const { accessToken, refreshToken } = data
        this.token.accessToken = accessToken
        this.token.refreshToken = refreshToken
        set(tokenTableName, accessToken)
        set(refreshTokenTableName, refreshToken)
        const returnUrl = get('returnUrl')
        returnUrl ? ((location.href = returnUrl), remove('returnUrl')) : (location.href = '/')
      } else {
        removeAll()
      }
      return succeeded
    },
    // 验证码登录
    async loginForCode(info: LoginParamsForCode) {
      const res = await postAccountByCode(info)
      const { data, succeeded } = res
      if (succeeded) {
        const { accessToken, refreshToken } = data
        this.token.accessToken = accessToken
        this.token.refreshToken = refreshToken
        set(tokenTableName, accessToken)
        set(refreshTokenTableName, refreshToken)
        const returnUrl = get('returnUrl')
        returnUrl ? ((location.href = returnUrl), remove('returnUrl')) : (location.href = '/')
      } else {
        removeAll()
      }
      return succeeded
    },
    logout() {
      this.token.accessToken = undefined
      this.token.refreshToken = undefined
      removeAll() // 清楚默认存储位置存储数据
      Permission().clear() // 登出权限清空
      set('returnUrl', location.pathname)
      this.clear()
      router.push({ path: '/account/login' })
    },
    async getAccoutInfo() {
      try {
        const res = await getAccountCurrentUser()
        const { data, succeeded } = res
        if (succeeded) {
          const {
            id = 0,
            userName = '',
            realName = '',
            avatar = '',
            email = '',
            phoneNumber = '',
            lawfirmId = 0,
            lawfirmName = '',
            isEnabled = true,
            licenseNumber = '',
            organizationUnitId = 0,
            isLawyer = false,
            userTypeName = '',
            sex = false
          } = data || {}

          this.userInfo.id = id
          this.userInfo.userName = userName
          this.userInfo.realName = realName
          this.userInfo.avatar = avatar || 'http://cdn.jingsh.com/oa/uploadfiles/user/avatar/2017/11/27/b2903567e91e485684d3b249246174f0.png'
          this.userInfo.phoneNumber = phoneNumber
          this.userInfo.lawfirmId = lawfirmId
          this.userInfo.lawfirmName = lawfirmName
          this.userInfo.isEnabled = isEnabled
          this.userInfo.licenseNumber = licenseNumber
          this.userInfo.organizationUnitId = organizationUnitId
          this.userInfo.sex = sex
          this.userInfo.email = email
          this.userInfo.isLawyer = isLawyer
          this.userInfo.userTypeName = userTypeName
          this.extraUserInfo = { ...data }
          notification.success({
            message: '欢迎',
            description: `欢迎 ${realName} 回来`,
            duration: 1
          })
        } else {
          this.clear()
        }
      } catch (error) {
        this.userInfo.id = undefined
        this.userInfo.userName = ''
        this.userInfo.realName = ''
        this.userInfo.avatar = 'http://cdn.jingsh.com/oa/uploadfiles/user/avatar/2017/11/27/b2903567e91e485684d3b249246174f0.png'
        this.userInfo.phoneNumber = ''
        this.userInfo.email = ''
        this.extraUserInfo = undefined
        throw new Error('登录信息错误')
      }
    },
    clear() {
      this.$reset()
    }
  }
})
