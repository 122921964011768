import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Finance: RouteRecordRaw = {
  path: 'finance',
  name: 'manage-finance',
  meta: { title: '财务管理', role: 'Admin.Finance', icon: 'oa_zhang', fullPath: '/manage/finance' },
  component: RouteView,
  redirect: '/manage/finance/project',
  children: [
    {
      path: 'project',
      name: 'manage-finance-project',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Manage', title: '财务管理', fullPath: '/manage/finance/project' },
      component: (): Component => import('@/views/manage/finance/project/index.vue')
    },
    {
      path: 'projectinfo/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-finance-info',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Show', hideInMenu: true, title: '项目详情', fullPath: '/manage/finance/projectInfo' },
      component: (): Component => import('@/views/manage/finance/info.vue')
    },
    // 开票管理
    {
      path: 'invoice',
      name: 'manage-finance-invoice',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Invoice', title: '发票管理', fullPath: '/manage/finance/invoice' },
      component: (): Component => import('@/views/manage/finance/invoice/index.vue')
    },
    //添加发票
    {
      path: 'invoice/create/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-finance-invoice-create',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '添加发票', fullPath: '/manage/finance/invoice/create' },
      component: (): Component => import('@/views/manage/finance/invoice/create.vue')
    },
    {
      path: 'invoice/update/:projectId(\\d+)/:projectTypeCode/:invoiceid',
      name: 'manage-finance-invoice-update',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '编辑开票', fullPath: '/manage/finance/invoice/update' },
      component: (): Component => import('@/views/manage/finance/invoice/update.vue')
    },
    // 缴费管理
    {
      path: 'payment',
      name: 'manage-finance-payment',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', title: '收费管理', fullPath: '/manage/finance/payment' },
      component: (): Component => import('@/views/manage/finance/payment/index.vue')
    },
    //添加缴费记录
    {
      path: 'payment/create/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-finance-payment-create',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Payment', hideInMenu: true, title: '添加收款记录', fullPath: '/manage/finance/payment/create' },
      component: (): Component => import('@/views/manage/finance/payment/create.vue')
    },
    {
      path: 'payment/update/:projectId(\\d+)/:projectTypeCode/:paymentId(\\d+)',
      name: 'manage-finance-payment-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', hideInMenu: true, title: '编辑缴费', fullPath: '/manage/finance/payment/update' },
      component: (): Component => import('@/views/manage/finance/payment/update.vue')
    },
    // 退款
    {
      path: 'refund',
      name: 'manage-finance-refund',
      meta: { icon: 'oa_refund', role: 'Admin.Finance.Refund', title: '退款管理', fullPath: '/manage/finance/refund' },
      component: (): Component => import('@/views/manage/finance/refund/index.vue')
    },
    //添加退款记录
    {
      path: 'refund/create/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-finance-refund-create',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Refund', hideInMenu: true, title: '添加退款记录', fullPath: '/manage/finance/refund/create' },
      component: (): Component => import('@/views/manage/finance/refund/create.vue')
    },
    {
      path: 'refund/update/:projectId(\\d+)/:projectTypeCode/:refundId(\\d+)',
      name: 'manage-finance-refund-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Refund', hideInMenu: true, title: '编辑退款', fullPath: '/manage/finance/refund/update' },
      component: (): Component => import('@/views/manage/finance/refund/update.vue')
    },
    // 台账
    {
      path: 'ledger',
      name: 'manage-finance-ledger',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Account', title: '台账列表', fullPath: '/manage/finance/ledger' },
      component: (): Component => import('@/views/manage/finance/ledger/index.vue')
    },
    {
      path: 'ledger/info/:userId(\\d+)',
      name: 'manage-finance-ledger-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Account', hideInMenu: true, title: '台账详情', fullPath: '/manage/finance/ledger/info' },
      component: (): Component => import('@/views/manage/finance/ledger/info.vue')
    },
    // 预存
    {
      path: 'prestore',
      name: 'manage-finance-prestore',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.PrestoredInvoice', title: '预存发票', fullPath: '/manage/finance/prestore' },
      component: (): Component => import('@/views/manage/finance/prestore/index.vue')
    },
    {
      path: 'prestore/info/:id(\\d+)',
      name: 'manage-finance-prestore-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.PrestoredInvoice', hideInMenu: true, title: '预存发票详情', fullPath: '/manage/finance/prestore/info' },
      component: (): Component => import('@/views/manage/finance/prestore/info.vue')
    },
    // 提款
    {
      path: 'draw',
      name: 'manage-finance-draw',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Draw', title: '提款管理', fullPath: '/manage/finance/draw' },
      component: (): Component => import('@/views/manage/finance/draw/index.vue')
    },

    {
      path: 'draw/Info/:userId(\\d+)/:realName',
      name: 'manage-finance-draw-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Draw', hideInMenu: true, title: '提款详情', fullPath: '/manage/finance/draw/info' },
      component: (): Component => import('@/views/manage/finance/draw/info.vue')
    },
    // 借款
    {
      path: 'loan',
      name: 'manage-finance-loan',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Loan', title: '借款管理', fullPath: '/manage/finance/loan' },
      component: (): Component => import('@/views/manage/finance/loan/index.vue')
    },
    {
      path: 'loan/info/:userId(\\d+)/:realName',
      name: 'manage-finance-loan-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Loan', hideInMenu: true, title: '借款详情', fullPath: '/manage/finance/loan/info' },
      component: (): Component => import('@/views/manage/finance/loan/info.vue')
    },
    // 调账
    {
      path: 'transfer',
      name: 'manage-finance-transfer',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Transfer', title: '调账管理', fullPath: '/manage/finance/transfer' },
      component: (): Component => import('@/views/manage/finance/transfer/index.vue')
    },
    // 补充历史记录
    {
      path: 'supplement',
      name: 'manage-finance-supplement',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Manage', title: '补充历史记录', fullPath: '/manage/finance/supplement' },
      component: (): Component => import('@/views/manage/finance/supplement/index.vue')
    },
    //补充开票退票记录
    {
      path: 'supplement/invoice/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-finance-supplement-invoice',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '补充开票退票记录', fullPath: '/manage/finance/supplement/invoice' },
      component: (): Component => import('@/views/manage/finance/supplement/invoiceCreate.vue')
    },
    //编辑补充的开票退票记录
    {
      path: 'supplement/invoice/update/:projectId(\\d+)/:projectTypeCode/:invoiceId(\\d+)',
      name: 'manage-finance-supplement-invoice-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '编辑补充的开票退票记录', fullPath: '/manage/finance/supplement/invoiceupdate' },
      component: (): Component => import('@/views/manage/finance/supplement/invoiceUpdate.vue')
    },
    //补充缴费退费记录
    {
      path: 'supplement/payment/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-finance-supplement-payment',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Payment', hideInMenu: true, title: '补充缴费退费记录', fullPath: '/manage/finance/supplement/payment' },
      component: (): Component => import('@/views/manage/finance/supplement/paymentCreate.vue')
    },
    //编辑补充的缴费退费记录
    {
      path: 'supplement/payment/update/:projectId(\\d+)/:projectTypeCode/:paymentId(\\d+)',
      name: 'manage-finance-supplement-payment-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', hideInMenu: true, title: '编辑补充的缴费退费记录', fullPath: '/manage/finance/supplement/paymentupdate' },
      component: (): Component => import('@/views/manage/finance/supplement/paymentUpdate.vue')
    }
  ]
}
