import request from '@/utils/request'
import type { RequestResult } from '@/types/request'
import type { AccountInfo, LoginParams, LoginParamsForCode, LoginResp, SmsCaptchaParams, CheckCodeParams, ChangePasswordByCodeParams } from '@/types/account'

/**
 * 登录
 * @param {LoginParams} params
 * @returns {LoginResp}
 */
export async function postAccountLogin(params: LoginParams) {
  return request.post<LoginParams, RequestResult<LoginResp>>('/api/Account/Login', params)
}

export async function postAccountByCode(params: LoginParamsForCode) {
  return request.post<LoginParamsForCode, RequestResult<LoginResp>>('/api/Account/LoginByCode', params)
}

/**
 * 获取当前登录用户信息
 * @returns {AccountInfo}
 */
export async function getAccountCurrentUser() {
  return request.get<any, RequestResult<AccountInfo>>('/api/Account/GetCurrentUser')
}

/**
 * 获取登录验证码，时效5分钟
 * @param {SmsCaptchaParams} params
 */
export async function getAccountSmsCaptcha(SmsCaptchaParams: SmsCaptchaParams) {
  return request.get<SmsCaptchaParams, RequestResult<boolean>>('/api/Account/GetCheckCode', { params: SmsCaptchaParams })
}

/**
 * 验证验证码是否有效
 * @param {CheckCodeParams} params
 */
export async function checkAccountValidateCode(CheckCodeParams: CheckCodeParams) {
  return request.post<CheckCodeParams, RequestResult<boolean>>('/api/Account/ValidateCheckCode', CheckCodeParams)
}

/**
 * 忘记密码
 * @param {ChangePasswordByCodeParams} params
 */
export async function resetAccountPassword(ChangePasswordByCodeParams: ChangePasswordByCodeParams) {
  return request.post<ChangePasswordByCodeParams, RequestResult<boolean>>('/api/Account/ChangePasswordByCode', ChangePasswordByCodeParams)
}

/**
 * 修改密码
 * @param {ChangePasswordByCodeParams} params
 */
export async function changeAccountPassword(ChangePasswordByCodeParams: ChangePasswordByCodeParams) {
  return request.post<ChangePasswordByCodeParams, RequestResult<boolean>>('/api/Account/ChangePasswordByCode', ChangePasswordByCodeParams)
}
