import request from '@/utils/request'
import type { RequestResult } from '@/types/request'
import type { UserRangeItem, PermissionItem, Grant, MenuGrant, GrantInfo } from '@/types/permission'

/**
 * 获取用户权限(数据权限)
 * @param {}
 * @returns {RequestResult<any>}
 */
export async function getPermissionUserRange() {
  return request.get<any, RequestResult<Array<UserRangeItem>>>(`/api/Permission/getUserRange`)
}

/**
 * 获取当前登录用户所有权限 (功能权限)
 * @returns { RequestResult<Array<PermissionItem>> }
 */
export async function getUserPermissions() {
  return request.get<null, RequestResult<Array<PermissionItem>>>(`/api/Permission/GetUserPermissions`)
}

//#endregion

/**
 * 岗位授权(数据\功能)
 * @param { Grant } data
 * @returns { RequestResult<null> }
 */
export async function grant(data: Grant) {
  return request.post<Grant, RequestResult<null>>(`/api/Permission/Grant`, data)
}

/**
 * 岗位功能授权
 * @param { Grant } data
 * @returns { RequestResult<null> }
 */
export async function Menugrant(data: MenuGrant) {
  return request.post<MenuGrant, RequestResult<null>>(`/api/Permission/GrantMenu`, data)
}

/**
 * 获取岗位权限
 * @param { number } id
 * @returns { RequestResult<GrantInfo> }
 */
export async function getGrantInfo(id: number) {
  return request.get<number, RequestResult<GrantInfo>>(`/api/Permission/GetOrganizationPermission/${id}`)
}
