import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Finance: RouteRecordRaw = {
  path: 'finance',
  name: 'finance',
  meta: { title: '财务管理', icon: 'oa_zhang', fullPath: '/finance' },
  component: RouteView,
  redirect: 'finance/invoce',
  children: [
    {
      path: 'ledger',
      name: 'finance-ledger',
      meta: { icon: 'oa_invoice', title: '我的台账', fullPath: '/finance/ledger' },
      component: (): Component => import('@/views/finance/ledger.vue')
    },
    {
      path: 'projectinfo/:projectId(\\d+)/:projectTypeCode',
      name: 'finance-projectinfo',
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '案件详情', fullPath: '/finance/projectinfo' },
      component: (): Component => import('@/views/finance/projectInfo.vue')
    },
    {
      path: 'invoice',
      name: 'finance-invoice',
      meta: { icon: 'oa_invoice', title: '开票记录', fullPath: '/finance/invoice' },
      component: (): Component => import('@/views/finance/invoice/index.vue')
    },
    {
      path: 'invoice/update/:projectId(\\d+)/:projectTypeCode/:invoiceid',
      name: 'finance-invoice-update',
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '编辑开票', fullPath: '/finance/invoice/update' },
      component: (): Component => import('@/views/finance/invoice/update.vue')
    },
    {
      path: 'payment',
      name: 'finance-payment',
      meta: { icon: 'oa_pay', title: '缴费记录', fullPath: '/finance/payment' },
      component: (): Component => import('@/views/finance/payment/index.vue')
    },
    {
      path: 'payment/update/:projectId(\\d+)/:projectTypeCode/:paymentid',
      name: 'finance-payment-update',
      meta: { icon: 'oa_pay', hideInMenu: true, title: '编辑缴费', fullPath: '/finance/payment/update' },
      component: (): Component => import('@/views/finance/payment/update.vue')
    },
    {
      path: 'refund',
      name: 'finance-refund',
      meta: { icon: 'oa_refund', title: '退款记录', fullPath: '/finance/refund' },
      component: (): Component => import('@/views/finance/refund/index.vue')
    },
    {
      path: 'refund/update/:projectId(\\d+)/:projectTypeCode/:refundid',
      name: 'finance-refund-update',
      meta: { icon: 'oa_refund', hideInMenu: true, title: '编辑退款', fullPath: '/finance/refund/update' },
      component: (): Component => import('@/views/finance/refund/update.vue')
    },
    // 调账
    {
      path: 'transfer',
      name: 'finance-transfer',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Transfer', title: '调账记录', fullPath: '/finance/transfer' },
      component: (): Component => import('@/views/finance/transfer/index.vue')
    }
  ]
}
