import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Project: RouteRecordRaw = {
  path: 'project',
  name: 'project',
  meta: { title: '业务办理', icon: 'oa_yewuguanli', fullPath: '/project' },
  component: RouteView,
  redirect: '/project/index',
  children: [
    {
      path: 'check',
      name: 'project-check',
      meta: { icon: 'oa_confirm', title: '冲突预检', fullPath: '/project/check' },
      component: (): Component => import('@/views/project/check/index.vue')
    },
    {
      path: 'info/:projectId(\\d+)/:projectTypeCode',
      name: 'project-info',
      meta: { icon: 'oa_lawfirm', hideInMenu: true, title: '项目详情', fullPath: '/project/info' },
      component: (): Component => import('@/views/project/info.vue')
    },
    {
      path: 'update/:projectId(\\d+)/:projectTypeCode',
      name: 'project-update',
      meta: { icon: 'oa_lawfirm', hideInMenu: true, title: '项目编辑', fullPath: '/project/update' },
      component: (): Component => import('@/views/project/update.vue')
    },
    {
      path: 'guide',
      name: 'project-guide',
      meta: { icon: 'oa_plus', title: '项目立项', fullPath: '/project/guide' },
      component: (): Component => import('@/views/project/guide.vue')
    },
    {
      path: 'index',
      name: 'project-index',
      meta: { icon: 'oa_yewuguanli', title: '项目管理', fullPath: '/project/index' },
      component: (): Component => import('@/views/project/index.vue')
    },
    {
      path: 'normal/create',
      name: 'project-normal-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '添加普通项目', fullPath: '/project/normal/create' },
      component: (): Component => import('@/views/project/normal/create.vue')
    },

    {
      path: 'bid/create',
      name: 'project-bid-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '添加招投标备案项目', fullPath: '/project/bid/create' },
      component: (): Component => import('@/views/project/bid/create.vue')
    },

    {
      path: 'bankruptcy/create',
      name: 'project-bankruptcy-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '添加破产管理人项目', fullPath: '/project/bankruptcy/create' },
      component: (): Component => import('@/views/project/bankruptcy/create.vue')
    },

    {
      path: 'customer/create',
      name: 'project-customer-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '添加客户服务入库项目', fullPath: '/project/customer/create' },
      component: (): Component => import('@/views/project/customer/create.vue')
    },

    {
      path: 'director/create',
      name: 'project-director-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '添加独董备案项目', fullPath: '/project/director/create' },
      component: (): Component => import('@/views/project/director/create.vue')
    },

    {
      path: 'risk/create',
      name: 'project-risk-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '添加风控内核项目', fullPath: '/project/risk/create' },
      component: (): Component => import('@/views/project/risk/create.vue')
    },

    // 审批我的项目
    {
      path: 'checkGuide/:projectId(\\d+)/:projectTypeCode',
      name: 'project-check-guide',
      meta: { icon: 'oa_pass', hideInMenu: true, title: '律师冲突自检-引导页', fullPath: '/project/checkguide' },
      component: (): Component => import('@/views/project/check/checkGuide.vue')
    },
    {
      path: 'checkMy/:projectId(\\d+)/:projectTypeCode',
      name: 'project-check-my',
      meta: { icon: 'oa_pass', hideInMenu: true, title: '提交风控审批', fullPath: '/project/checkmy' },
      component: (): Component => import('@/views/project/check/checkMy.vue')
    },
    // 审批团队成员项目
    {
      path: 'checkTeamList',
      name: 'project-check-team-list',
      meta: { icon: 'oa_pass', title: '审核团队成员项目列表', fullPath: '/project/checkTeamList' },
      component: (): Component => import('@/views/project/check/teamList.vue')
    },
    {
      path: 'checkTeam/:projectId(\\d+)/:projectTypeCode',
      name: 'project-check-team',
      meta: { icon: 'oa_pass', hideInMenu: true, title: '审核团队成员项目', fullPath: '/project/checkTeam' },
      component: (): Component => import('@/views/project/check/checkTeam.vue')
    },
    {
      path: 'letter',
      name: 'project-letter',
      meta: { icon: 'oa_letter', title: '函书申领', fullPath: '/project/letter' },
      component: (): Component => import('@/views/project/letter.vue')
    },

    {
      path: 'applayendlist',
      name: 'project-applayend-list',
      meta: { icon: 'oa_jlpc', title: '申请结案', fullPath: '/project/applayendlist' },
      component: (): Component => import('@/views/project/applayEnd/index.vue')
    },
    {
      path: 'applayend/:projectId(\\d+)/:projectTypeCode',
      name: 'project-applayend',
      meta: { icon: 'oa_jlpc', hideInMenu: true, title: '申请结案', fullPath: '/project/applayend' },
      component: (): Component => import('@/views/project/applayEnd/applayEnd.vue')
    },
    {
      path: 'financelist',
      name: 'project-finance-list',
      meta: { icon: 'oa_pay', title: '开票\\缴费\\退款', fullPath: '/project/financelist' },
      component: (): Component => import('@/views/project/finance/index.vue')
    },
    {
      path: 'invoice/create/:projectId(\\d+)/:projectTypeCode',
      name: 'project-invoice-create',
      props: true,
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '申请开票', fullPath: '/project/invoice/create' },
      component: (): Component => import('@/views/finance/invoice/create.vue')
    },
    {
      path: 'payment/create/:projectId(\\d+)/:projectTypeCode',
      name: 'project-payment-create',
      meta: { icon: 'oa_pay', hideInMenu: true, title: '缴费登记', fullPath: '/project/payment/create' },
      component: (): Component => import('@/views/finance/payment/create.vue')
    },
    {
      path: 'refund/create/:projectId(\\d+)/:projectTypeCode',
      name: 'project-refund-create',
      meta: { icon: 'oa_refund', hideInMenu: true, title: '申请退款', fullPath: '/project/refund/create' },
      component: (): Component => import('@/views/finance/refund/create.vue')
    }
  ]
}
