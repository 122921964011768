import request from '@/utils/request'

import type { RequestResult } from '@/types/request'
import type { OSSSignal, OSSSignalData } from '@/types/oss'

/**
 * OSS 上传签名
 * @param {OSSSignalData} signalData
 * @returns {RequestResult<OSSSignal>}
 */
export async function GetOssSignature(signalData: OSSSignalData) {
  return request.get<OSSSignalData, RequestResult<OSSSignal>>(`/api/File/GetUrlSignature`, { params: signalData })
}
