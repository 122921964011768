import { defineStore } from 'pinia'

import { getPaymentChannelList } from '@/api/system/paymentChannel'

import type { PaymentChannelItem } from '@/types/system/paymentChannel'

export const SystemPaymentchannel = defineStore('SystemPaymentchannel', {
  state: () => {
    return {
      list: [] as Array<PaymentChannelItem>
    }
  },
  getters: {
    PaymentchannelList: state => state.list as Array<PaymentChannelItem>
  },
  actions: {
    async getList() {
      try {
        const res = await getPaymentChannelList()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    }
  }
})
