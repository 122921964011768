import request from '@/utils/request'

import type { AppLawfirmParams } from '@/types/base'
import type { RequestResult } from '@/types/request'

/**
 * 获取当前律所功能配置
 * @returns
 */
export const getLawfirmParams = (lawfirmId: number) => {
  return request.get<null, RequestResult<AppLawfirmParams>>(`/api/Config/GetLawfirmParams/${lawfirmId}`)
}
