import { defineStore } from 'pinia'

import type { AppLawfirmParams, TimeRangeType, UserConfigItem } from '@/types/base'
import { getLawfirmParams } from '@/api/base'
import { getUserConfig } from '@/api/user/manage'

export enum TimeRangeTypeCode {
  today = 1,
  three = 2,
  seven = 3,
  thirty = 4,
  ninety = 5,
  week = 6,
  month = 7,
  quarter = 8,
  year = 9
}

export enum LayoutTypeCode {
  User,
  Manage,
  Account
}

export const Base = defineStore('Base', {
  state: () => {
    return {
      viewLayout: LayoutTypeCode.User,
      timeRange: {
        [TimeRangeTypeCode.today]: { key: TimeRangeTypeCode.today, title: '今日', color: 'success' },
        [TimeRangeTypeCode.three]: { key: TimeRangeTypeCode.three, title: '近三天', color: 'success' },
        [TimeRangeTypeCode.seven]: { key: TimeRangeTypeCode.seven, title: '近七天', color: 'success' },
        [TimeRangeTypeCode.thirty]: { key: TimeRangeTypeCode.thirty, title: '近30日', color: 'success' },
        [TimeRangeTypeCode.ninety]: { key: TimeRangeTypeCode.ninety, title: '近90日', color: 'success' },
        [TimeRangeTypeCode.week]: { key: TimeRangeTypeCode.week, title: '本周', color: 'success' },
        [TimeRangeTypeCode.month]: { key: TimeRangeTypeCode.month, title: '本月', color: 'success' },
        [TimeRangeTypeCode.quarter]: { key: TimeRangeTypeCode.quarter, title: '本季度', color: 'success' },
        [TimeRangeTypeCode.year]: { key: TimeRangeTypeCode.year, title: '本年', color: 'success' }
      },
      params: {},
      teamParams: {}
    } as {
      viewLayout: LayoutTypeCode
      timeRange: TimeRangeType
      params: AppLawfirmParams
      teamParams: UserConfigItem
    }
  },
  getters: {
    layoutType: state => state.viewLayout,
    timeRangeType: state => state.timeRange,
    contractPayable: state => state.params?.isEnabledContractPayable || false,
    contractTravel: state => state.params?.isEnabledContractTravelAmount || false,
    paymentType: state => state.params?.h5Pay,
    letterESing: state => state.params?.isEnabledESign || false,
    UserParams: state => state.teamParams
  },
  actions: {
    async appLawfirmParams(lawfirmId: number) {
      try {
        const res = await getLawfirmParams(lawfirmId)
        const { data, succeeded } = res
        succeeded ? (this.params = data) : ''
        return res
      } catch (error) {}
    },

    async appUserParams() {
      try {
        const res = await getUserConfig()
        const { data, succeeded } = res
        succeeded ? (this.teamParams = data.teamConfig) : ''
        return res
      } catch (error) {}
    }
  }
})
