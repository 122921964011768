<template>
  <template v-if="props.data.length > 0">
    <a-list item-layout="horizontal" :data-source="props.data" :loading="props.loading">
      <template #renderItem="{ item }: { item: NotificationItem }">
        <a-list-item>
          <template #actions>
            <template v-if="props.showActions">
              <a key="list-loadmore-more" @click="emit('handle', { type: 'show', id: item.id })">查看</a>
            </template>
          </template>
          <a-list-item-meta>
            <template #title>
              <a @click="emit('handle', { type: 'show', id: item.id })"> {{ item.title }}</a>
            </template>
            <template #description>
              {{ item.content }}
              <br />
              <span> 推送时间: {{ dayjs(item.creationTime).format('YYYY-MM-DD') }} </span>
              <template v-if="props.showUser">
                <br />
                接收人:
                <a-space :size="24" wrap>
                  <template v-for="items in item.users" :key="items.lawfirmId">
                    {{ items.realName }}
                  </template>
                </a-space>
              </template>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </template>
  <template v-else>
    <a-empty :description="props.emptyText" />
  </template>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'

  import type { NotificationItem } from '@/types/notification/manage'

  defineOptions({ name: 'UserNotificationList' })

  const props = defineProps({
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array<NotificationItem>,
      required: true,
      default: []
    },
    emptyText: {
      type: String,
      default: '暂无相关消息提醒'
    },
    showControl: {
      type: Boolean,
      default: true
    },
    showUser: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    }
  })

  const emit = defineEmits(['handle'])
</script>
<style lang="less" scoped>
  .ant-list {
    .ant-list-item {
      padding: 12px 0;
    }
  }

  :deep(.ant-space) {
    margin-bottom: 0 !important;
  }
</style>
