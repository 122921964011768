<template>
  <notice-dropdown class="action" :count="0" :loading="loading" :layout="props.layout">
    <a-tabs v-model:activeKey="activeKey">
      <template v-for="item in tabs" :key="item.key">
        <a-tab-pane :tab="item.title">
          <template v-if="item.key === 'notice'">
            <notice-list-views :data="noticeList" :empty-text="item.emptyText" :show-control="false" :show-lawfirm="false" :loading="noticeLoading" class="layout_notice" @handle="showMore" />
          </template>
          <template v-if="item.key === 'notification'">
            <notification-list-views :data="notificationList" :empty-text="item.emptyText" :loading="notificationLoading" :show-user="false" @handle="showMore" />
          </template>
          <template v-if="item.key === 'tips'">{{ `${item.title}${item.emptyText}` }}</template>
        </a-tab-pane>
      </template>
      <template #rightExtra>
        <template v-if="showActions">
          <a-button type="link" @click="showMore"> 更多 </a-button>
        </template>
      </template>
    </a-tabs>
  </notice-dropdown>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { PropType, ref, watch } from 'vue'
  import { useRouter } from 'vue-router'

  import { getNoticePageList } from '@/api/notice'
  import { getNotificationPageList } from '@/api/notification'
  import { getNotificationPageListForManage } from '@/api/notification/manage'

  import { Base, LayoutTypeCode } from '@/stores/modules/base'

  import type { NoticeItem } from '@/types/notice'

  import NoticeDropdown from './noticeDropdown.vue'
  import NoticeListViews from '@/views/components/notice/list.vue'
  import NotificationListViews from '@/views/components/notification/list.vue'

  defineOptions({ name: 'NoticeIcon' })

  const router = useRouter()

  const baseStore = Base()
  const { layoutType } = storeToRefs(baseStore)
  const props = defineProps({
    layout: {
      type: String as PropType<'manage' | 'user'>,
      default: 'manage'
    }
  })

  const loading = ref(false)
  const activeKey = ref('notice')
  const showActions = ref(false)

  const tabs = ref([
    {
      key: 'notice',
      title: '公告',
      emptyText: '暂未发布相关公告',
      showViewMore: true
    },
    {
      key: 'notification',
      title: '消息',
      emptyText: '暂未收到相关推送消息',
      showViewMore: true
    },
    {
      key: 'tips',
      title: '系统提醒',
      emptyText: '暂无相关系统通知',
      showViewMore: true
    }
  ])

  //#region 公告

  const noticeList = ref<Array<NoticeItem>>([])
  const noticeTotalCount = ref<number>(0)
  const noticeLoading = ref<boolean>(false)
  const getNoticeList = async () => {
    noticeLoading.value = true
    try {
      const res = await getNoticePageList({ pageIndex: 1, pageSize: 15 })
      const {
        succeeded,
        data: { items, totalCount }
      } = res
      succeeded ? ((noticeList.value = items), (noticeTotalCount.value = totalCount), (showActions.value = totalCount > 0)) : ''
      noticeLoading.value = false
    } catch (error) {
      noticeLoading.value = false
    }
  }
  //#endregion

  //#region 消息
  const notificationList = ref<Array<any>>([])
  const notificationTotalCount = ref<number>(0)
  const notificationLoading = ref<boolean>(false)

  const getNotificationList = async () => {
    notificationLoading.value = true
    try {
      const res = layoutType.value === LayoutTypeCode.Manage ? await getNotificationPageListForManage({ pageIndex: 1, pageSize: 20 }) : await getNotificationPageList({ pageIndex: 1, pageSize: 20 })

      const {
        succeeded,
        data: { items, totalCount }
      } = res
      succeeded ? ((notificationList.value = items), (notificationTotalCount.value = totalCount), (showActions.value = totalCount > 0), (notificationLoading.value = false)) : ''
      notificationLoading.value = false
    } catch (error) {
      notificationLoading.value = false
    }
  }
  //#endregion

  const getData = () => {
    switch (activeKey.value) {
      case 'notice':
        getNoticeList()
        break
      case 'notification':
        getNotificationList()
        break

      default:
        break
    }
  }

  watch(
    () => activeKey.value,
    () => {
      getData()
    },
    {
      immediate: true
    }
  )

  const showMore = () => {
    switch (activeKey.value) {
      case 'notice':
        props.layout === 'manage' ? router.push({ path: '/manage/administration/notice' }) : router.push({ path: '/administration/notice' })
        break
      case 'notification':
        props.layout === 'manage' ? router.push({ path: '/manage/notification' }) : router.push({ path: '/notification' })
        break
      default:
        break
    }
  }
</script>

<style lang="less" scoped>
  /** layout notice **/

  :deep(.ant-tabs-nav) {
    padding-right: 10px;
    padding-left: 20px;

    .ant-btn {
      padding: 4px;
    }
  }

  :deep(.ant-tabs-content) {
    padding: 0 20px 10px;
  }

  :deep(.layout_notice) {
    .ant-list-item {
      padding: 6px 0;

      & + .ant-list-item {
        margin-top: 6px;
      }
    }
  }

  :deep(.ant-tabs-tabpane) {
    text-align: left;
  }
</style>
